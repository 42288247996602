export class TravelAlbum {

  label: string;
  url: string;

  constructor(album: TravelAlbum = <TravelAlbum>{}) {
    this.label = album.label;
    this.url = album.url;
  }

}
