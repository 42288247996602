<header class="masthead">
  <app-header></app-header>
</header>

<section id="about" class="about-section text-center">
  <app-contact></app-contact>
</section>

<section id="projects" class="projects-section bg-light">
  <app-articles></app-articles>
</section>

<section id="travel" class="signup-section">
    <app-travel></app-travel>
</section>

<app-footer></app-footer>
