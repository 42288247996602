import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {Article} from './model/article.model';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  private _articles: Article[] = [];
  private _currentPage: Article[] = [];
  private _pageSize = 2;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    this
      .httpClient
      .get<Article[]>('/assets/data/articles.json')
      .pipe(first())
      .subscribe(
        (articles: any) => {
          this._articles = articles;
          this.loadPage(1);
        }
      );
  }

  get articles(): Article[] {
    return this._articles;
  }

  get currentPage(): Article[] {
    return this._currentPage;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  loadPage(page: number) {
    const from = (page - 1) * this._pageSize;
    const to = (from + this._pageSize < this._articles.length) ? from + this._pageSize : this._articles.length;

    if (from >= this._articles.length) {
      this._currentPage = [];
    } else {
      this._currentPage = this._articles.slice(from, to);
    }
  }

}
