import {TravelAlbum} from './travel-album.model';

export class VisitedCountry {

  code: string;
  label: string;
  albums: TravelAlbum[];

  constructor(visitedCountry: VisitedCountry = <VisitedCountry>{}) {
    this.code = visitedCountry.code;
    this.label = visitedCountry.label;

    this.albums = (visitedCountry.albums != null)
      ? visitedCountry.albums.map(album => new TravelAlbum(album))
      : [];
  }

}
