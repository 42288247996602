import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import * as highmaps from 'highcharts/modules/map.src';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TravelComponent} from './travel/travel.component';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ContactComponent} from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { ArticlesComponent } from './articles/articles.component';

@NgModule({
  declarations: [
    AppComponent,
    TravelComponent,
    ContactComponent,
    FooterComponent,
    MenuComponent,
    HeaderComponent,
    ArticlesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartModule,
    HttpClientModule,
    NgbModule
  ],
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [highmaps]
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
