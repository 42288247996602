<div class="container">
  <div class="row">
    <div class="col-lg-8 mx-auto">
      <h2 class="text-white mb-4">My Resume</h2>
      <p class="text-white-50">

        <iframe
          src="https://docs.google.com/viewer?url=http://www.sgerard.be/assets/resume-GERARD_Sebastien.pdf&embedded=true"
          width="100%" height="800" style="border: none;">
        </iframe>
      </p>
    </div>
  </div>
</div>
