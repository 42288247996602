<div class="container">
  <div class="row">
    <h2 class="text-black-50 mb-4">Articles I wrote</h2>
  </div>

  <div class="row">
    <div class="card-deck">
      <div class="card" style="width: 30rem;" *ngFor="let article of currentPage">
        <img class="card-img-top" src="{{article.thumbnail}}" alt="Article title">
        <div class="card-body">
          <h5 class="card-title">
            {{article.title}}
          </h5>
          <p class="card-text">
            {{article.description}}
          </p>
          <a
            href="{{article.url}}"
            target="_blank" class="btn btn-primary">Read it</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <ngb-pagination [collectionSize]="articles.length" [pageSize]="pageSize" (pageChange)="loadPage($event)" aria-label="Default pagination"></ngb-pagination>
  </div>
</div>
