import {Component, OnInit, ViewChild} from '@angular/core';
import {MapChart} from 'angular-highcharts';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {VisitedCountry} from './model/visited-country.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.css']
})
export class TravelComponent implements OnInit {

  private _mapChart: MapChart;
  private _selectedCountry: VisitedCountry;

  @ViewChild('content', { static: true }) public content: any;

  constructor(private httpClient: HttpClient,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this._mapChart = new MapChart({});

    forkJoin(
      this.httpClient.get('/assets/data/visited_countries.json'),
      this.httpClient.get('/assets/highcharts/custom/world.geo.json')
    )
      .pipe(first())
      .subscribe(
        (results: any) => {
          this._mapChart = this.initializeMap(
            results[1],
            (<any[]>results[0]).map(country => new VisitedCountry(country))
          );
        }
      );
  }

  get mapChart(): MapChart {
    return this._mapChart;
  }

  get selectedCountry(): VisitedCountry {
    return this._selectedCountry;
  }

  private initializeMap(mapData: any, countries: VisitedCountry[]): MapChart {
    return new MapChart({
        title: {
          text: 'Visited countries'
        },

        subtitle: {
          text: 'Number countries: ' + countries.length + '.'
        },

        legend: {
          enabled: false
        },

        mapNavigation: {
          enabled: true
        },

        plotOptions: {
          series: {
            events: {
              click: (e) => {
                this.openCountry(e.point['hc-key'], countries);
              }
            }
          }
        },

        series: [
          {
            type: 'map',
            data: countries.map(visitedCountry => [visitedCountry.code, 1]),
            mapData: mapData,
            color: '#64a19d',

            dataLabels: {
              enabled: false,

              formatter: function () {
                if (this.point.value) {
                  return this.point.name;
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormat: '{point.name}'
            }
          }
        ]
      }
    );
  }

  private openCountry(selectedCountry: string, countries: VisitedCountry[]) {
    this._selectedCountry = countries.find(country => country.code === selectedCountry);

    if ((this._selectedCountry != null) && (this._selectedCountry.albums.length > 0)) {
      this.modalService.open(this.content);
    }
  }

}
