<div class="container">
  <div class="row">
    <div class="col-md-10 col-lg-8 mx-auto text-center">

      <i class="far fa-paper-plane fa-2x mb-2 text-white"></i>
      <h2 class="text-white mb-5">My Travelling Experience</h2>
    </div>
  </div>

  <div [chart]="mapChart"></div>
</div>



<ng-template #content let-modal class="modal fade">
  <div class="modal-header">
    <h4 class="modal-title">{{selectedCountry.label}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Albums:</p>

    <ul>
      <li *ngFor="let album of selectedCountry.albums">
        <a href="{{album.url}}" target="_blank">{{album.label}}</a>
      </li>
    </ul>
  </div>

  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-info" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
