<div class="container d-flex h-100 align-items-center">
  <div class="mx-auto text-center">
    <h1 class="mx-auto my-0 text-uppercase">
      Sébastien Gérard
    </h1>
    <h2 class="text-white-50 mx-auto mt-2 mb-5">
      Developer, fan of music, folk dance, photography, travelling, cycling...
    </h2>
    <a href="#about" class="btn btn-primary js-scroll-trigger">View my resume</a>
  </div>
</div>
