<footer class="bg-black small text-center text-white-50">
  <div class="social d-flex justify-content-center">
    <a href="https://twitter.com/sebge2" class="mx-2" target="_blank">
      <i class="fab fa-twitter"></i>
    </a>
    <a href="https://www.facebook.com/sebastien.gerard" class="mx-2" target="_blank">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a href="https://github.com/sebge2" class="mx-2" target="_blank">
      <i class="fab fa-github"></i>
    </a>
    <a href="https://www.linkedin.com/in/gerardsebastien" class="mx-2" target="_blank">
      <i class="fab fa-linkedin"></i>
    </a>
  </div>

  <div class="container">
    Copyright &copy; 2019
  </div>
</footer>
